import React, { useState, useEffect, useRef } from "react";
import "../navbar/navbar.css";
import Modal from "../homeSection/modal/modal";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const navbarRef = useRef(null);

  const handleContactMeClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setIsOpen(false);
    }
  };

  // Chiudi la navbar quando si clicca fuori
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleScrollEvent = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("scroll", handleScrollEvent);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("scroll", handleScrollEvent);
    };
  }, [isOpen]);

  return (
    <div ref={navbarRef}>
      <nav className="navbar">
        <div className="navbar-brand">
          <span className="greenParticles">{"< "}</span>
          {"Alessandro Tucci"}
          <span className="greenParticles">{" /> "}</span>
        </div>
        <div className={`navbar-links ${isOpen ? "active" : ""}`}>
          <li>
            <button onClick={() => handleScroll("about")}>About</button>
          </li>
          <li>
            <button onClick={() => handleScroll("technologies")}>
              Technologies
            </button>
          </li>
          <li>
            <button onClick={() => handleScroll("experience")}>
              Experience
            </button>
          </li>
          <li>
            <button className="green-btn" onClick={handleContactMeClick}>
              Contact
            </button>
          </li>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </nav>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default Navbar;
