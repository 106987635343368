import React from "react";
import "./card.css";

const Card = ({ descrizione1, descrizione2, immagine }) => {
  return (
    <div className="card">
      <div className="card-image">
        <img src={immagine} alt="Card Image" />
      </div>
      <div className="card-text">
        <p>
          {descrizione1}
          <br></br>
          {descrizione2}
        </p>
      </div>
    </div>
  );
};

export default Card;
