import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./careerTimeline.css";
import axa from "./assets/Axa.png";
import intesa from "./assets/Intesa.png";

const CareerTimeline = () => {
  return (
    <div className="career-timeline">
      <VerticalTimeline>
        {/* First work experience */}
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{
            background: "#3333335b",
            color: "#ffffffab",
            boxShadow: "5px 11px 23px 2px rgba(0,0,0,0.44)",
          }}
          contentArrowStyle={{ borderRight: "7px solid #3333335b" }}
          date="September 2021 - Present"
          dateClassName="right-date"
          iconStyle={{ background: "#4caf50", color: "#fff" }}
          icon={
            <div className="career-img-container">
              <img src={axa} alt="axa" className="career-img-axa" />
            </div>
          }
        >
          <h3 className="vertical-timeline-element-title">
            Software Engineer and Data Analyst
          </h3>
          <p>Worked as a consultant by FinconsGroup Ltd for Axa Insurance</p>
        </VerticalTimelineElement>
        {/* Second work experience */}
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{
            background: "#3333335b",
            color: "#ffffffab",
            boxShadow: "5px 11px 23px 2px rgba(0,0,0,0.44)",
          }}
          contentArrowStyle={{ borderRight: "7px solid #3333335b" }}
          date="January 2020 - August 2021"
          iconStyle={{ background: "#006E30", color: "#fff" }}
          icon={
            <div className="career-img-container">
              <img src={intesa} alt="intesa" className="career-img-intesa" />
            </div>
          }
        >
          <h3 className="vertical-timeline-element-title">Software Engineer</h3>
          <p>
            Worked as a consultant by SCAI Tecno Ltd for Intesa Sanpaolo welfare
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default CareerTimeline;
