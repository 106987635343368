import React from "react";
import "./career.css";
import CareerTimeline from "./timeline/careerTimeline";

const Career = () => {
  return (
    <div className="career-container">
      <div className="career-title-container">
        <h1>Experience</h1>
      </div>
      <div className="career-timeline-container">
        <CareerTimeline />
      </div>
    </div>
  );
};

export default Career;
