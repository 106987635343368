import csharpIcon from "./assets/c#.png";
import netIcon from "./assets/NET.png";
import seleniumIcon from "./assets/selenium.png";
import pythonIcon from "./assets/py.png";
import typescriptIcon from "./assets/typescript.png";
import angularIcon from "./assets/angular.png";
import reactIcon from "./assets/reactjs.png";
import sqlIcon from "./assets/SQL.png";
import figmaIcon from "./assets/figma.png";

const technologies = [
  {
    name: "C#",
    icon: csharpIcon,
  },
  {
    name: ".NET",
    icon: netIcon,
  },
  {
    name: "Selenium",
    icon: seleniumIcon,
  },
  {
    name: "Python",
    icon: pythonIcon,
  },
  {
    name: "TypeScript",
    icon: typescriptIcon,
  },
  {
    name: "Angular",
    icon: angularIcon,
  },
  {
    name: "React",
    icon: reactIcon,
  },
  {
    name: "SQL",
    icon: sqlIcon,
  },
  {
    name: "Figma",
    icon: figmaIcon,
  },
];

export default technologies;
