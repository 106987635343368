import React, { useState } from "react";
import { Typewriter } from "react-simple-typewriter";
import { useMediaQuery } from "react-responsive";
import "./home.css";
import Modal from "./modal/modal";
import Earth from "./earth/earth";

const Home = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleHireMeClick = () => {
    console.log("I know you want to hire me :)");
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleResumeDownload = () => {
    const pdfUrl = "/PDF/Tucci_Alessandro_RESUME.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Tucci_Alessandro_RESUME.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const isDesktop = useMediaQuery({ query: "(min-width: 481px)" });

  return (
    <section className="two-column-section">
      <div className="left-column">
        <div className="main-text">
          <div className="greetings">
            {isDesktop && <span className="hi">HI👋</span>}
            {!isDesktop && <span className="hi">HI,</span>}
            <br />
            <span className="im">I'M</span> &nbsp;
            {/* Typewriter inline for Desktop, below for Mobile */}
            {isDesktop ? (
              <span style={{ color: "#00CF5D" }}>
                <Typewriter
                  words={[
                    "Software Engineer",
                    "Data Analyst",
                    "UI/UX Designer",
                    "AI Enthusiast",
                  ]}
                  loop={0}
                  cursor
                  cursorStyle="|"
                  typeSpeed={90}
                  deleteSpeed={70}
                  delaySpeed={2000}
                />
              </span>
            ) : (
              <div className="typewriter-mobile">
                <Typewriter
                  words={[
                    "Software Engineer",
                    "Data Analyst",
                    "UI/UX Designer",
                    "AI Enthusiast",
                  ]}
                  loop={0}
                  cursor
                  cursorStyle="|"
                  typeSpeed={90}
                  deleteSpeed={70}
                  delaySpeed={2000}
                />
              </div>
            )}
          </div>
          <div className="p-Wrapper">
            <p>
              A dedicated Software Engineer with 4 years of experience
              specializing in full-stack web and mobile application development.
              I have a passion for building dynamic, user-friendly websites and
              mobile apps that deliver exceptional user experiences. With a
              strong foundation in both front-end and back-end technologies, I
              bring your ideas to life from concept to completion.
            </p>
            <div className="btn-wrapper">
              <button className="green-btn" onClick={handleHireMeClick}>
                Hire Me
              </button>
              <button className="outline-btn" onClick={handleResumeDownload}>
                Get Resume
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="right-column">{isDesktop && <Earth />}</div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
    </section>
  );
};

export default Home;
