import React, { useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./modal.css";

const Modal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then((response) => {
        //console.log("SUCCESS!", response.status, response.text);
        toast.success("Email sent successfully!");
        resetForm();
        setTimeout(onClose, 1500);
      })
      .catch((err) => {
        console.error("FAILED...", err);
        toast.error("Failed to send email.");
      });
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div>
      <ToastContainer hideProgressBar={true} autoClose={1500} />
      <div className="modal-overlay" onClick={handleClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button className="close-btn" onClick={handleClose}>
            ×
          </button>
          <h3>GET IN TOUCH</h3>
          <h2>Contact</h2>
          <form className="form" onSubmit={handleSubmit}>
            <span className="input-span">
              <label htmlFor="name" className="label">
                Your Name:
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="What's your name?"
                required
              />
            </span>
            <span className="input-span">
              <label htmlFor="email" className="label">
                Your Email:
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="What's your email?"
                required
              />
            </span>
            <span className="input-span">
              <label htmlFor="message" className="label">
                Your Message:
              </label>
              <textarea
                name="message"
                id="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="What's your message?"
                required
              ></textarea>
            </span>
            <input className="submit" type="submit" value="Send" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
