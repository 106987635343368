import React from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import "./tech.css";
import BallCanvas from "./ball/ball";
import technologies from "../../costants/index";

const Tech = () => {
  //console.log(Array.isArray(technologies));
  //console.log(technologies);

  return (
    <div className="tech-container">
      <div className="tech-title-container">
        <h1>Technologies</h1>
      </div>
      <div className="tech-technologies-container">
        {technologies.map((technology) => (
          <div key={technology.name}>
            <BallCanvas icon={technology.icon} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tech;
