import "./App.css";
import Navbar from "./components/navbar/navbar";
import Home from "./components/homeSection/home";
import Overview from "./components/overviewSection/overview";
import Tech from "./components/tech/tech";
import Career from "./components/careerTimeline/career";
import Projects from "./components/myprojects/projects";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Benvenuti nel mio portfolio! Qui puoi vedere i miei progetti e il mio lavoro."
        />
        <meta
          name="keywords"
          content="portfolio, sviluppatore, progetti, lavoro"
        />
        <meta property="og:title" content="Il mio Portfolio" />
        <meta
          property="og:description"
          content="Benvenuti nel mio portfolio! Qui puoi vedere i miei progetti e il mio lavoro."
        />
        <meta property="og:image" content="URL_dell'immagine_di_preview" />
        <meta property="og:url" content="https://www.tuosito.com" />
      </Helmet>
      <div className="App">
        <Navbar />
        <section id="about">
          <Home />
        </section>
        <section id="technologies">
          <Overview />
        </section>
        <section id="experience">
          <Tech />
        </section>
        <section id="contact">
          <Career />
        </section>
        {/* <Projects /> */}
      </div>
    </div>
  );
}

export default App;
