import React, { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useGLTF, Stage, PresentationControls } from "@react-three/drei";

const Earth = () => {
  // Check if it's a mobile device
  const isMobile = window.innerWidth <= 480;

  return (
    <Canvas
      dpr={[1, 2]}
      camera={{
        fov: isMobile ? 20 : 10, // Increase field of view on mobile devices
        position: isMobile ? [0, 0, 12] : [0, 0, 20], // Zoom in on mobile devices
      }}
    >
      <PresentationControls>
        <Stage environment={"sunset"} shadows={false}>
          <EarthModel isMobile={isMobile} />
        </Stage>
      </PresentationControls>
    </Canvas>
  );
};

function Model(props) {
  const { scene } = useGLTF("/Planet.glb");
  const scaleValue = props.isMobile ? 10 : 5; // Enlarge the model even more on mobile devices
  return <primitive object={scene} scale={scaleValue} {...props} />;
}

const EarthModel = ({ isMobile }) => {
  const group = useRef();

  // Function for automatic rotation
  useFrame(() => {
    if (group.current) {
      group.current.rotation.y += 0.01; // Rotation speed
    }
  });

  return (
    <group ref={group}>
      <Model isMobile={isMobile} />
    </group>
  );
};

export default Earth;
