import React from "react";
import { useMediaQuery } from "react-responsive";
import "./overview.css";
import Card from "./card/card";
import backEnd from "./assets/backend.png";
import frontEnd from "./assets/frontend.png";
import uiux from "./assets/ux.png";
import programmer from "./assets/programmerEdit.svg";
import Earth from "../homeSection/earth/earth";

const Overview = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 481px)" });

  return (
    <div className="overview-container">
      {/* Left Column (1/4) */}
      <div className="overview-left-column">
        <div className="overview-centered-image">
          {isMobile && <Earth />}
          {isDesktop && <img src={programmer} alt="Centered Image" />}
        </div>
      </div>

      {/* Right Column (3/4) */}
      <div className="overview-right-column">
        {/* Title container */}
        <div className="overview-title-container">
          <h2>Overview</h2>
        </div>

        {/* Paragraph container */}
        <div className="overview-para-container">
          <p>
            As a Full-Stack Software Engineer, I have expertise across the
            entire development spectrum from designing and managing databases to
            crafting intuitive UI/UX. My skill set spans both front-end and
            back-end technologies, allowing me to develop seamless and robust
            applications that are as efficient as they are visually appealing.
          </p>
        </div>

        {/* Container per le 3 card */}
        <div className="overview-cards-container">
          <Card
            descrizione1={"FrontEnd"}
            descrizione2={"Developer"}
            immagine={frontEnd}
          />
          <Card
            descrizione1={"BackEnd"}
            descrizione2={"Developer"}
            immagine={backEnd}
          />
          <Card
            descrizione1={"UI/UX"}
            descrizione2={"Designer"}
            immagine={uiux}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
